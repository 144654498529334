<script>
import API from "@/plugins/axios-method";
import {serverUrl} from "@/helpers/constant/serverUrl";

export default {
  name: "FundingRequestBasicInfo",
  props: {
    fr: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  methods: {
    exportToExcel(){
      API.get('/funding_requests/' + this.fr.uniqId + '/export_as_excel')
          .then(response => {
            const {excelFileUrl} = response.data;

            const link = document.createElement('a');
            link.target = '_blank';
            link.href = serverUrl + '/' + excelFileUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-header py-2">
      <h4 class="card-title d-flex justify-content-between"><span>Informations de base</span> <b-button role="button" @click="exportToExcel" title="exporter vers un fichier excel" class="btn-soft-info"><i class=" bx bx-file"></i> Exporter Excel</b-button></h4>
    </div>
    <div class="card-body">
      <b-list-group flush class="list-group-fill-success">
        <BListGroupItem href="#" class="list-group-item-action"><i class="ri-money-dollar-circle-line align-middle me-2"></i> {{fr.amount?.toLocaleString()}} XAF</BListGroupItem>
        <BListGroupItem href="#" class="list-group-item-action"><i class="bx bx-hourglass align-middle me-2"></i><BBadge v-if="fr.status === 0" variant="secondary">En attente</BBadge>
          <BBadge v-if="fr.status === 0" variant="secondary">Créée</BBadge>
          <BBadge v-else-if="fr.status === 1" variant="warning">En Attente</BBadge>
          <BBadge v-else-if="fr.status === 2" variant="success">Acceptée</BBadge>
          <BBadge v-else-if="fr.status === 3" variant="danger">Refusée</BBadge>
        </BListGroupItem>
        <BListGroupItem href="#" class="list-group-item-action"><i class="bx bx-calendar align-middle me-2"></i>  {{fr.createdAt ? fr.createdAt.split('T')[0] : null}}</BListGroupItem>
      </b-list-group>
    </div>
  </div>
</template>

<style scoped>

</style>