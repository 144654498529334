<script>
import {getApplicantFullNAme} from "@/helpers/functions";

export default {
  name: "FundingRequestCardApplicantInfo",
  methods: {getApplicantFullNAme},
  props: {
    fr: {
      type: Object,
      default(){
        return {}
      }
    }
  },
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Informations du demandeur</h4>
    </div>
    <div class="card-body">
      <b-list-group flush class="list-group-fill-success">
        <BListGroupItem class="list-group-item-action"><i class="ri-user-line align-middle me-2"></i> {{getApplicantFullNAme(fr.applicant)}}</BListGroupItem>
        <BListGroupItem class="list-group-item-action"><i class="ri-phone-line align-middle me-2"></i>{{fr.account?.user.phoneNumber}}</BListGroupItem>
        <BListGroupItem title="Compte demandeur" class="list-group-item-action"><i class="ri-wallet-line align-middle me-2"></i>  <router-link :to="'/admin/accounts/' + fr.account.uniqId ">{{fr.account.identifier}}</router-link></BListGroupItem>
      </b-list-group>
    </div>
  </div>
</template>

<style scoped>

</style>