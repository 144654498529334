<script>
import Layout from "@/layouts/main.vue"
import PageHeader from "@/components/page-header.vue";
import DataLoader from "@/components/data-loader.vue";
import API from "@/plugins/axios-method";
import FundingRequestBasicInfo from "@/components/dashboard/funding/request/FundingRequestBasicInfo.vue";
import FundingRequestCardApplicantInfo
  from "@/components/dashboard/funding/request/FundingRequestCardApplicantInfo.vue";
import FundingRequestProject from "@/components/dashboard/funding/request/FundingRequestProject.vue";
import FundingRequestMarketAnalysis from "@/components/dashboard/funding/request/FundingRequestMarketAnalysis.vue";
import FundingRequestDecisionModal from "@/components/dashboard/funding/request/funding-request-decision-modal.vue";
import {APPROVED, REJECTED} from "@/helpers/constant/funding-request-decision";
import NotFound from "@/components/not-found.vue";
export default {
  name: "single-granted",
  components: {
    NotFound,
    FundingRequestDecisionModal,
    FundingRequestMarketAnalysis,
    FundingRequestProject,
    FundingRequestCardApplicantInfo,
    FundingRequestBasicInfo,
    DataLoader,
    PageHeader,
    Layout
  },
  props: {},
  data() {
    return {
      fundingDecisionModalState: false,
      notFound: false,
      decisionModalTitle: null,
      fundingRequest: null,
      showDataLoader: true,
      decision: REJECTED,
    }
  },

  mounted() {
    this.getFundingRequest()
  },
  methods: {
    getFundingRequest() {
      API.get('/funding_requests/' + this.$route.params.id)
          .then(response => {
            this.fundingRequest = response.data
          })
          .catch(e => {
            if(e.response.status === 404){
              this.notFound = true
            }
          })
          .finally(() => {
            this.showDataLoader = false
          })
    },
    openFundingRequestDecisionModal(decision){
      if (decision === REJECTED){
        this.decisionModalTitle = "Rejeter la demande de financement de " + this.fundingRequest.amount.toLocaleString() + " XAF"
      } else if(decision === APPROVED){
        this.decisionModalTitle = "Approuver la demande de financement de " + this.fundingRequest.amount.toLocaleString() + " XAF"
      }
      this.decision = decision
      this.fundingDecisionModalState = true
    },
    onModalClose(){
      this.fundingDecisionModalState = false
      this.decisionModalTitle = null
      this.decision = null
    }
  },
  computed: {
    canShowData(){
      return !this.showDataLoader && this.fundingRequest
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader
        :title="'demande de financement'"
        :show-search="false"
        :show-add-button="false"
        :show-decision-button="canShowData && !(this.fundingRequest?.status === 2 || this.fundingRequest?.status === 3)"
        @openFundingRequestDecisionModal="openFundingRequestDecisionModal"
    />
    <DataLoader v-if="showDataLoader"/>
    <NotFound v-if="!showDataLoader && notFound"/>
    <section v-if="canShowData">
      <FundingRequestDecisionModal
          @onModalClose="onModalClose"
          :title="decisionModalTitle"
          :decision="decision"
          :funding-request="fundingRequest"
          v-model="fundingDecisionModalState"
      />
      <div class="row">
        <div class="col-md-6">
          <FundingRequestBasicInfo
              :fr="fundingRequest"
          />
        </div>
        <div class="col-md-6">
          <FundingRequestCardApplicantInfo
              :fr="fundingRequest"
          />
        </div>
      </div>
    </section>
    <section v-if="canShowData">
      <div class="row">
        <div class="col-md-6">
          <FundingRequestProject
              :project="fundingRequest.projects[0]"
          />
        </div>
        <div class="col-md-6">
          <FundingRequestMarketAnalysis
              :ma="fundingRequest.marketAnalysis[0]"
          />
        </div>
      </div>
    </section>
  </Layout>
</template>

<style scoped>

</style>