<script>
export default {
  name: "FundingRequestMarketAnalysis",
  props:{
    ma: {
      type: Object,
      default(){
        return {}
      }
    }
  }
}
</script>

<template>
  <b-accordion>
    <b-accordion-item title="Analyse du marché" visible>
        <div class="">
          <div class="mt-2">
            <h5>Description</h5>
            <div class="" v-html="ma.description"></div>
          </div>
          <div class="mt-2">
            <h5>Taille</h5>
            <p>{{ma.size}} clients</p>
          </div>
          <div class="mt-2">
            <h5>Avantages</h5>
            <p v-for="(a, index) in ma.advantages" :key="index">{{index + 1 }}. {{a.name}}</p>
          </div>
          <div class="mt-2">
            <h5>Concurrents</h5>
            <p v-for="(c, index) in ma.competitors" :key="index">{{index + 1 }}. {{c.name}}</p>
          </div>
        </div>
    </b-accordion-item>
  </b-accordion>
</template>

<style scoped>

</style>