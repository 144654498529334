<script>
import Modal from "@/components/modal.vue";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import {APPROVED, REJECTED} from "@/helpers/constant/funding-request-decision";
import API from "@/plugins/axios-method";
export default {
  name: "funding-request-decision-modal",
  components: {Modal},
  props: {
    fundingRequest: {
      type: Object,
      default(){return null}
    },
    title: {
      type: String,
      default: "Approuver"
    },
    value: {},
    decision: {
      type: String,
      default: REJECTED,
      required: true
    }
  },
  setup(){
    return { v$: useVuelidate() }
  },
  data(){
    return {
      comment: null,
      status: null,
      visible: false,
      isSubmitting: false,
      isSubmitted: false,
    }
  },
  watch: {
    value(newValue) {
      this.visible = newValue
    },
  },
  methods: {
    formSubmit(){
      if (this.validateForm()) return
      const answer = confirm('Voulez vous vraiment ' + this.title + ' ?')
      if (!answer) return
      this.isSubmitted = true
      API.post('/admin/funding_request_decision', {
        status: this.decision === APPROVED ? 1 : 0,
        comment: this.comment,
        fundingRequest: this.fundingRequest['@id']
      })
          .then(() => {
            window.location.reload()
          })
          .catch(() => {
            this.isSubmitted = false
          })
    },
    clearForm(){
      this.v$.$reset()
      this.comment = null
    },
    onModalClose(){
      this.clearForm()
      this.visible = false
      this.isSubmitted = false
      this.isSubmitting = false
      this.$emit('onModalClose', 'funding-request-decision')
    },
    validateForm(){
      this.v$.$validate()
      return !!this.v$.$error
    },
  },
  validations: {
    comment: {
      required: helpers.withMessage("Le commentaire est requis", required),
    },
  }
}
</script>

<template>
    <Modal :title="title" @closeModal="onModalClose" v-model="visible">
        <div class="  ">
          <form @submit.prevent="formSubmit">
            <div class="mb-3">
              <label for="comment" class="form-label">Commentaire</label>
              <b-form-textarea placeholder="Laisser un commentaire" :class="{'is-invalid': v$.comment.$errors.length }" v-model="comment"/>
              <div class="invalid-feedback" v-for="error in v$.comment.$errors" :key="error.$uid">
                <span>{{ error.$message }}</span>
              </div>
            </div>
            <div class="mt-4">
              <button :disabled="isSubmitted" class="btn btn-success w-100" type="submit">
                <div v-if="isSubmitted" style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <span v-if="!isSubmitted">Enregistrer</span>
              </button>
            </div>
          </form>
        </div>
    </Modal>
</template>

<style scoped>

</style>