<script>
export default {
  name: "FundingRequestProject",
  props: {
    project: {
      type: Object,
      default(){
        return {}
      }
    }
  }
}
</script>

<template>
  <b-accordion>
    <BAccordionItem title="PROJET" visible>
      <div class="">
        <div class="">
          <div class="row">
            <div class="col">
              <div class="">
                <h5>Intitulé</h5>
                <p>{{ project.title }}</p>
              </div>
              <div class="">
                <h5>Description</h5>
                <div class="" v-html="project.description"></div>
              </div>
              <div class="">
                <h5>Objectif</h5>
                <div class="" v-html="project.objective"></div>
              </div>
              <div class="">
                <h5>Durée</h5>
                <p class="">{{ project.duration }} mois</p>
              </div>
              <div class="">
                <h5>Secteurs d'activités</h5>
                <div class="">
                  <p class="" v-for="(business, indexLine) in project.linesOfBusiness" :key="indexLine">{{ business.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BAccordionItem>
  </b-accordion>
</template>

<style scoped>

</style>